<script setup lang="ts">
import backendCall from "../../services/AxiosService";
import {onMounted, ref} from "vue";

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import { useToast } from 'primevue/usetoast';
import TrainTimeStatistics from "./TrainTimeStatistics.vue";
import EditTimeRecordDialog from "../../components/billing/EditTimeRecordDialog.vue";
import {useAuthStore} from "../../stores/authStore";
import NewBillingTimeRecordDialog from "@/components/billing/NewBillingTimeRecordDialog.vue";
import NewWaitTimeRecordDialog from "@/components/billing/NewWaitTimeRecordDialog.vue";
import NewTrackProtectionTimeRecordDialog from "@/components/billing/NewTrackProtectionTimeRecordDialog.vue";
import JobTimeStatistics from "@/views/billing/JobTimeStatistics.vue";
import { RouterLink } from 'vue-router';

const authStore = useAuthStore();
const toast = useToast();

const props = defineProps({
  jobId: String
})

const activeTab = ref(0);
const tabs = ref([
  {label: 'Billing Time', icon: 'pi pi-clock'},
  {label: 'Wait Time', icon: 'pi pi-clock'},
  {label: 'Track Protection', icon: 'pi pi-hashtag'},
]);

const newBillingRecordDialogVisible = ref(false);
const newWaitTimeRecordDialogVisible = ref(false);
const newTrackProtectionTimeRecordDialogVisible = ref(false);
const editTimeRecordDialogVisible = ref(false);
const loading = ref(false);

const trainId = ref();
const trainArrivalTime = ref();
const billingTimeRecords = ref([]);
const waitTimeRecords = ref([]);
const trackProtectionTimeRecords = ref([]);
const totalTime = ref('');
const totalWaitTime = ref('');
const workersOnTrainCount = ref(0);
const workersOnTrain = ref([]);
const totalElectrics = ref(0);
const totalDomestics = ref(0);
const totalGensets = ref(0);
const totalRequests = ref(0);
const totalTrackProtectionTime = ref('');
const jobDetails = ref(null);
const requestGroupId = ref(null);

const selectedTimeRecord = ref();

const showEditTimeRecordDialog = (data: unknown) => {
  editTimeRecordDialogVisible.value = true;
  selectedTimeRecord.value = data;
}

const getJobDetails = async () => {
  loading.value = true;
  try {
    const response = await backendCall.get(`billing/jobs/${props.jobId}`);
    
    if (response.data && response.data.success) {
      const data = response.data.data;
      
      if (data.job) {
        // Set the request group ID if it exists
        if (data.job.request_group_id) {
          requestGroupId.value = data.job.request_group_id;
        }
      }
      
      jobDetails.value = data.job;
      billingTimeRecords.value = data.jobTime || [];
      totalTime.value = data.totalTime || '00:00:00';
      
      // These fields might be added in future API updates
      // waitTimeRecords.value = data.waitTimeRecords || [];
      // trackProtectionTimeRecords.value = data.trackProtectionTimeRecords || [];
      // totalWaitTime.value = data.totalWaitTime || '00:00:00';
      // totalTrackProtectionTime.value = data.totalTrackProtectionTime || '00:00:00';
      // workersOnTrainCount.value = data.workersOnTrainCount || 0;
      // workersOnTrain.value = data.workersOnTrain || [];
      // trainId.value = data.trainId;
      // trainArrivalTime.value = data.trainArrivalTime;
      // totalElectrics.value = data.electricCount || 0;
      // totalDomestics.value = data.domesticCount || 0;
      // totalGensets.value = data.gensetCount || 0;
      // totalRequests.value = data.requestCount || 0;
    } else {
      toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to load job details', life: 3000 });
    }
  } catch (error) {
    console.error('There was a problem with the fetch operation:', error);
    toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to load job details', life: 3000 });
  } finally {
    loading.value = false;
  }
}

onMounted(async () => {
  await getJobDetails();
})
</script>

<template>
  <div class="bg-surface-0 dark:bg-surface-950 px-6 py-0 md:px-8 lg:px-18">
    <div class="flex mb-4 items-start flex-col lg:justify-between lg:flex-row">
      <div>
        <div class="font-medium text-2xl text-surface-900 dark:text-surface-0">
          Job Details <span v-if="jobDetails">#{{ jobDetails.job_id }}</span>
        </div>
        <div v-if="jobDetails && jobDetails.description" class="text-surface-600 dark:text-surface-400 mt-1">
          {{ jobDetails.description }}
        </div>
        <div v-if="requestGroupId" class="text-surface-600 dark:text-surface-400 mt-1">
          Request Group: 
          <RouterLink :to="`/request-groups/${requestGroupId}`" class="text-primary hover:text-primary-600">
            #{{ requestGroupId }}
          </RouterLink>
        </div>
      </div>
      <div class="mt-2 lg:mt-0">
        <Button icon="pi pi-arrow-left" label="Back to Jobs" class="p-button-outlined" 
                @click="$router.push('/billing/jobs')"></Button>
      </div>
    </div>
    
    <JobTimeStatistics :train-id="trainId" :total-billing-time="totalTime" :total-wait-time="totalWaitTime" :total-track-protection-time="totalTrackProtectionTime"
                       :workersOnTrain="workersOnTrain" :workers-on-train-count="workersOnTrainCount"
                       :train-arrival-time="trainArrivalTime" :total-electrics="parseInt(totalElectrics) || 0"
                       :total-gensets="parseInt(totalGensets) || 0" :total-requests="parseInt(totalRequests) || 0"
                       :total-domestics="parseInt(totalDomestics) || 0"></JobTimeStatistics>
    
    <div class="bg-surface-50 dark:bg-surface-950 p-4 md:p-2 lg:p-2">
      <ul class="bg-surface-0 dark:bg-surface-900 p-0 m-0 list-none flex overflow-x-auto select-none">
        <template v-for="(item, index) of tabs" :key="index">
          <li class="flex-1">
            <a
                class="cursor-pointer px-6 py-4 flex items-center justify-center border-b-2 transition-colors duration-150"
                :class="{
                            'border-primary text-primary hover:border-primary-emphasis': activeTab === index,
                            'text-surface-700 dark:text-surface-100 border-transparent hover:border-surface-300 dark:hover:border-surface-500': activeTab !== index
                        }"
                @click="activeTab = index"
            >
              <i :class="item.icon" class="mr-2"/>
              <span class="font-medium">{{ item.label }}</span>
            </a>
          </li>
        </template>
      </ul>
    </div>
    <div v-if="activeTab === 0" id="billing-time-records">
      <div class="flex items-start flex-col lg:justify-between lg:flex-row mb-2">
        <div></div>
        <div class="flex justify-between">
          <div class="font-medium text-3xl text-surface-900 dark:text-surface-0">Billing Time Records</div>
        </div>
        <div>
          <Button v-if="authStore.user?.roles.includes(4000)" class="mx-2" label="New" icon="pi pi-plus"
                  @click="newBillingRecordDialogVisible = true"></Button>
          <NewBillingTimeRecordDialog v-model:visible="newBillingRecordDialogVisible" :trainid="props.trainid"
                                      @refresh-times="getJobDetails"></NewBillingTimeRecordDialog>
        </div>
      </div>
      <DataTable :value="billingTimeRecords" tableStyle="min-width: 50rem" :loading="loading" stripedRows>
        <Column field="first_name" header="First Name"></Column>
        <Column field="last_name" header="Last Name"></Column>
        <Column field="duration" header="Duration (hh:mm:ss)"></Column>
        <Column header="Start Time">
          <template #body="slotProps">
            {{
              slotProps.data.start_time ? new Date(slotProps.data.start_time).toLocaleString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hourCycle: 'h23'
              }) : 'N/A'
            }}
          </template>
        </Column>
        <Column header="End Time">
          <template #body="slotProps">
            {{
              slotProps.data.end_time ? new Date(slotProps.data.end_time).toLocaleString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hourCycle: 'h23'
              }) : 'N/A'
            }}
          </template>
        </Column>
        <Column v-if="authStore.user?.roles.includes(4000)" header="Actions">
          <template #body="slotProps">
            <Button @click="showEditTimeRecordDialog(slotProps.data)" size="small" severity="warn" outlined text
                    icon="pi pi-pen-to-square"></Button>
          </template>
        </Column>
      </DataTable>
    </div>
    <div v-if="activeTab === 1" id="wait-time-records">
      <div class="flex items-start flex-col lg:justify-between lg:flex-row mb-2">
        <div></div>
        <div class="flex justify-between">
          <div class="font-medium text-3xl text-surface-900 dark:text-surface-0">Wait Time Records</div>
        </div>
        <div>
          <Button v-if="authStore.user?.roles.includes(4000)" class="mx-2" label="New" icon="pi pi-plus"
                  @click="newWaitTimeRecordDialogVisible = true"></Button>
          <NewWaitTimeRecordDialog v-model:visible="newWaitTimeRecordDialogVisible" :trainid="props.trainid"
                                      @refresh-times="getJobDetails"></NewWaitTimeRecordDialog>
        </div>
      </div>
      <DataTable :value="waitTimeRecords" tableStyle="min-width: 50rem" :loading="loading" stripedRows>
        <Column field="first_name" header="First Name"></Column>
        <Column field="last_name" header="Last Name"></Column>
        <Column field="duration" header="Duration (hh:mm:ss)"></Column>
        <Column header="Start Time">
          <template #body="slotProps">
            {{
              slotProps.data.start_time ? new Date(slotProps.data.start_time).toLocaleString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hourCycle: 'h23'
              }) : 'N/A'
            }}
          </template>
        </Column>
        <Column header="End Time">
          <template #body="slotProps">
            {{
              slotProps.data.end_time ? new Date(slotProps.data.end_time).toLocaleString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hourCycle: 'h23'
              }) : 'N/A'
            }}
          </template>
        </Column>
      </DataTable>
    </div>
    <div v-if="activeTab === 2" id="track-protection-records">
      <div class="flex items-start flex-col lg:justify-between lg:flex-row mb-2">
        <div></div>
        <div class="flex justify-between">
          <div class="font-medium text-3xl text-surface-900 dark:text-surface-0">Track Protection Records</div>
        </div>
        <div>
          <Button v-if="authStore.user?.roles.includes(4000)" class="mx-2" label="New" icon="pi pi-plus"
                  @click="newTrackProtectionTimeRecordDialogVisible = true"></Button>
          <NewTrackProtectionTimeRecordDialog v-model:visible="newTrackProtectionTimeRecordDialogVisible" :trainid="props.trainid"
                                      @refresh-times="getJobDetails"></NewTrackProtectionTimeRecordDialog>
        </div>
      </div>
      <DataTable :value="trackProtectionTimeRecords" tableStyle="min-width: 50rem" :loading="loading" stripedRows>
        <Column field="first_name" header="First Name"></Column>
        <Column field="last_name" header="Last Name"></Column>
        <Column field="duration" header="Duration (hh:mm:ss)"></Column>
        <Column header="Start Time">
          <template #body="slotProps">
            {{
              slotProps.data.start_time ? new Date(slotProps.data.start_time).toLocaleString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hourCycle: 'h23'
              }) : 'N/A'
            }}
          </template>
        </Column>
        <Column header="End Time">
          <template #body="slotProps">
            {{
              slotProps.data.end_time ? new Date(slotProps.data.end_time).toLocaleString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hourCycle: 'h23'
              }) : 'N/A'
            }}
          </template>
        </Column>
      </DataTable>
    </div>
    <EditTimeRecordDialog v-model:visible="editTimeRecordDialogVisible" :time-record="selectedTimeRecord"
                          @refresh-times="getJobDetails"></EditTimeRecordDialog>
  </div>
</template>

<style scoped>
/* You can add custom styles here if needed */
</style>