<script setup lang="ts">
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import AutoComplete from 'primevue/autocomplete';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import {PropType, ref} from "vue";
import backendCall from "@/services/AxiosService";
import {useToast} from "primevue/usetoast";

const toast = useToast();

const visible = defineModel<boolean>();

const props = defineProps({
  gensetsMaster: {
    type: Array as PropType<Array<{ genset_number?: string, has_def: boolean }>>,
    required: true
  }
});

const emit = defineEmits(['refreshGensets']);

const formData = ref({
  genset: null,
  inType: null,
});

const gensetSuggestions = ref<Array<{ genset_number?: string, has_def: boolean }>>([]);

const searchGensets = (event: { query: string }) => {
  const query = event.query.trim().toUpperCase();
  gensetSuggestions.value = props.gensetsMaster.filter((genset) =>
      genset.genset_number?.includes(query)
  );
};

const submitNewPowerpack = async () => {
  try {
    await backendCall.post('power-pack/inventory/wit/in', formData.value);
    emit('refreshGensets');
    visible.value = false;
    toast.add({severity: 'success', summary: 'Success', detail: 'Genset Added.', life: 5000});
  } catch (e) {
    toast.add({severity: 'error', summary: 'Error', detail: 'Error Submitting Genset.', life: 5000});
  }
}
</script>

<template>
  <Dialog
      v-model:visible="visible"
      append-to="body"
      modal
      :style="{ width: '20w' }"
      :draggable="false"
      :resizable="false">
    <template #header>
      <div class="flex flex-col gap-2">
        <h1 class="m-0 text-surface-900 dark:text-surface-0 font-semibold text-xl leading-normal">
          Add Genset
        </h1>
      </div>
    </template>
    <form class="flex flex-col justify-center gap-4 mt-4">
      <div>
        <label for="genset-number" class="block mb-1 text-color text-base">
          Genset Number
        </label>
        <AutoComplete
            v-model="formData.genset"
            :suggestions="gensetSuggestions"
            @complete="searchGensets"
            optionLabel="genset_number"
            id="genset-number"
            name="genset-number"
            class="w-full"/>
      </div>
      <div>
        <label for="genset-number" class="block mb-1 text-color text-base">
          In Type
        </label>
        <Select fluid v-model="formData.inType" :options="['deadhead', 'teardown', 'bo-swap', 'ingate']"/>
      </div>
    </form>

    <template #footer>
      <div class="w-full flex gap-4 justify-end border-t border-surface pt-8">
        <Button label="Cancel" text @click="visible = false"/>
        <Button label="Submit" rounded @click="submitNewPowerpack"/>
      </div>
    </template>
  </Dialog>
</template>