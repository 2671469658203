<script setup lang="ts">
import {onMounted, Ref, ref} from "vue";
import backendCall from "@/services/AxiosService";
import DataTable, {DataTablePageEvent, DataTableSortEvent} from "primevue/datatable";

const gensetBuilds: Ref<any[]> = ref([]);


const totalRecords = ref(0);
const loading = ref(false);

// PrimeVue expects these specific properties for lazy loading
const lazyParams = ref({
  first: 0,
  rows: 10,
  page: 0,
  sortField: 'build_id',
  sortOrder: -1
});

const fetchBuilds = async () => {
  try {
    loading.value = true;

    const params = {
      page: lazyParams.value.page + 1, // Convert to 1-based for backend
      rows: lazyParams.value.rows,
      sortField: lazyParams.value.sortField,
      sortOrder: lazyParams.value.sortOrder
    };

    const response = await backendCall.get('power-pack/builds', { params });
    const data: {content: any[], totalRecords: number} = response.data;

    if (Array.isArray(data.content)) {
      gensetBuilds.value = data.content;
      totalRecords.value = data.totalRecords;
    }
  } catch (error) {
    console.error('There was a problem with the axios operation:', error);
  } finally {
    loading.value = false;
  }
};

const onPage = (event: DataTablePageEvent) => {
  lazyParams.value = {
    ...lazyParams.value,
    first: event.first,
    rows: event.rows,
    page: Math.floor(event.first / event.rows)
  };
  fetchBuilds();
};

const onSort = (event: DataTableSortEvent) => {
  lazyParams.value = {
    ...lazyParams.value,
    sortField: event.sortField as string,
    sortOrder: event.sortOrder as number
  };
  fetchBuilds();
};

onMounted(async() => {
  await fetchBuilds();
})

</script>

<template>
  <div class="bg-surface-0 dark:bg-surface-950 px-6 py-8 md:px-12 lg:px-20">
    <div class="flex flex-col md:items-center md:justify-center md:flex-row">
      <div class="font-medium text-3xl text-surface-900 dark:text-surface-0">Genset Builds</div>
      <!--      <div class="mt-4 md:mt-0">-->
      <!--        <Button @click="newPretripDialogVisible = true" label="New" class="mr-2" icon="pi pi-plus" />-->
      <!--      </div>-->
    </div>
  </div>
  <DataTable
      :value="gensetBuilds"
      :lazy="true"
      :total-records="totalRecords"
      :loading="loading"
      dataKey="build_id"
      :rows="lazyParams.rows"
      :first="lazyParams.first"
      :rows-per-page-options="[10, 20, 50, 100]"
      :sort-order="lazyParams.sortOrder"
      :sort-field="lazyParams.sortField"
      @page="onPage"
      @sort="onSort"
      paginator
      striped-rows
      removableSort
      tableStyle="min-width: 50rem"
  >
    <Column field="build_id" header="ID"></Column>
    <Column field="call_in_time" header="Call In Time">
      <template #body="slotProps">
        {{ slotProps.data.call_in_time ? new Date(slotProps.data.call_in_time).toLocaleString("en-US", {hourCycle: "h23"}) : '' }}
      </template>
    </Column>
    <Column field="start_time" header="Start Time">
      <template #body="slotProps">
        {{ slotProps.data.start_time ? new Date(slotProps.data.start_time).toLocaleString("en-US", {hourCycle: "h23"}) : '' }}
      </template>
    </Column>
    <Column field="genset_number" header="Genset"></Column>
    <Column field="track_id" header="Track"></Column>
    <Column header="Entered By">
      <template #body="slotProps">
        {{ slotProps.data.entered_by_first_name + " " + slotProps.data.entered_by_last_name }}
      </template>
    </Column>
<!--    <Column header="Action">-->
<!--      <template #body="slotProps">-->
<!--        <Button text icon="pi pi-search"/>-->
<!--      </template>>-->
<!--    </Column>-->

  </DataTable>

</template>
