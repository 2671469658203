<script setup lang="ts">
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import {onMounted, Ref, ref, watch} from 'vue';
import backendCall from "../../services/AxiosService";
import {useToast} from "primevue/usetoast";
import {useReferenceStore} from "@/stores/referenceStore";

const props = defineProps({
  trainid: String
})

const emit = defineEmits(['refreshTimes']);

const referenceStore = useReferenceStore();

const toast = useToast();

const visible = defineModel('visible');

const workerOptions = ref([]);

const timeRecord = ref({
  protectionTracks: [],
  startUser: null,
  endUser: null,
  startTime: null,
  endTime: null,
})

watch(() => referenceStore.tracks, (newValue) => {
  allTracks.value = newValue;
});

const trackSuggestions = ref<string[]>([]);
const allTracks = ref();


const searchTracks = (event: { query: string }) => {
  const query = event.query.trim().toUpperCase();

  if (query.length === 0) {
    trackSuggestions.value = [...allTracks.value];
  } else {
    trackSuggestions.value = allTracks.value.filter((track: { track_id?: string, track_name?: string }) =>
        track.track_id?.toUpperCase()?.startsWith(query) || track.track_name?.toUpperCase()?.startsWith(query)
    );
  }
};


const getUsers = async () => {
  const usersCall = await backendCall.get('dispatch/workers');
  workerOptions.value = usersCall.data.data.users;
}

const recordType: Ref<"billing" | "wait" | "track" | undefined> = ref(undefined);

const submitTimeRecord = async () => {
  try {
    await backendCall.post(`time/trains/${props.trainid}/new-track-protection-record`, timeRecord.value);
    timeRecord.value.startUser = null;
    timeRecord.value.endUser = null;
    timeRecord.value.startTime = null;
    timeRecord.value.endTime = null;
    visible.value = false;
    emit("refreshTimes");
  } catch (e) {
    toast.add({severity: 'error', summary: 'Error', detail: 'Error Submitting New Time Record.', life: 5000});

  }
}

onMounted(() => {
  if (!allTracks.value) {
    referenceStore.fetchReferenceData()
  }
  getUsers();
})

</script>

<template>
  <Dialog v-model:visible="visible" append-to="body" modal :breakpoints="{ '960px': '75vw', '640px': '96vw' }"
          :style="{ width: '52vw' }" header="Add Billing Time Record" :draggable="false" :resizable="false">
    <template #header>
      <div class="flex flex-col gap-2">
        <h1 class="m-0 text-surface-900 dark:text-surface-0 font-semibold text-xl leading-normal">Add Track Protection Record</h1>
        <!--          <span class="text-surface-600 dark:text-surface-200 text-base">Quis enim lobortis scelerisque fermentum dui faucibus in ornare quam.</span>-->
      </div>
    </template>
    <form class="flex flex-col gap-4 mt-4">
      <AutoComplete
          :multiple="true"
          v-model="timeRecord.protectionTracks"
          :suggestions="trackSuggestions"
          :optionLabel="(trackSuggestions) => trackSuggestions.track_id"
          :forceSelection="false"
          @complete="searchTracks"
          placeholder="Select tracks"
      />
      <div class="flex gap-4">
      <div class="w-full">
        <label for="start-worker" class="block mb-1 text-color text-base">Start Worker</label>
        <InputGroup>
          <InputGroupAddon>
            <i class="pi pi-user"></i>
          </InputGroupAddon>
          <Select id="start-worker" name="start-worker" dropdown-icon="pi pi-user" v-model="timeRecord.startUser" option-value="id"
                  :option-label="(data) => { return data.first_name + ' ' + data.last_name}" :options="workerOptions"
                  class="w-full">
            <template #option="option">
              {{ option.option.first_name + ' ' + option.option.last_name }}
            </template>
          </Select>
        </InputGroup>
      </div>
      <div class="w-full">
        <label for="end-worker" class="block mb-1 text-color text-base">End Worker</label>
        <InputGroup>
          <InputGroupAddon>
            <i class="pi pi-user"></i>
          </InputGroupAddon>
          <Select id="end-worker" name="end-worker" dropdown-icon="pi pi-user" v-model="timeRecord.endUser" option-value="id"
                  :option-label="(data) => { return data.first_name + ' ' + data.last_name}" :options="workerOptions"
                  class="w-full">
            <template #option="option">
              {{ option.option.first_name + ' ' + option.option.last_name }}
            </template>
          </Select>
        </InputGroup>
      </div>
      </div>
      <div class="flex gap-4">
        <div class="w-full">
          <label for="start-time" class="block mb-1 text-color text-base">Start Time</label>
          <InputGroup>
            <input-group-addon>
              <i class="pi pi-clock"/>
            </input-group-addon>
            <DatePicker id="start-time" v-model="timeRecord.startTime" showTime hourFormat="24" fluid/>
          </InputGroup>
        </div>
        <div class="w-full">
          <label for="end-time" class="block mb-1 text-color text-base">End Time</label>
          <InputGroup>
            <input-group-addon>
              <i class="pi pi-clock"/>
            </input-group-addon>
            <DatePicker id="end-time" v-model="timeRecord.endTime" showTime hourFormat="24" fluid/>
          </InputGroup>
        </div>
      </div>
    </form>
    <template #footer>
      <div class="w-full flex gap-4 justify-end border-t border-surface pt-8">
        <Button
            label="Cancel"
            text @click="visible = false"/>
        <Button
            label="Submit"
            rounded
            @click="submitTimeRecord"/>
      </div>
    </template>
  </Dialog>
</template>

