import axios, { AxiosInstance } from "axios";

const backendCall: AxiosInstance = axios.create({
    baseURL: import.meta.env.VITE_API_BASE_URL,
    timeout: 30000,
    withCredentials: true
});

// Add request interceptor to handle HTTP methods
backendCall.interceptors.request.use((config) => {
    if (config.method) {
        config.method = config.method.toLowerCase();
    }
    return config;
});

export default backendCall;