<script setup lang="ts">
import Button from 'primevue/button';
import {onMounted, ref} from "vue";
import backendCall from "@/services/AxiosService";
const newPretripDialogVisible = ref(false);

const powerPackPretrips = ref([]);

const getPowerPackPretrips = async () => {
  const getPowercpPretripsCall = await backendCall.get('/power-pack/pretrip');
  powerPackPretrips.value = getPowercpPretripsCall.data.data;
}

onMounted(async() => {
 await getPowerPackPretrips();
})

</script>

<template>
  <NewPretripDialog v-if="newPretripDialogVisible" v-model:visible="newPretripDialogVisible" @refresh-pretrips="getPowerPackPretrips"></NewPretripDialog>
  <div class="bg-surface-0 dark:bg-surface-950 px-6 py-8 md:px-12 lg:px-20">
    <div class="flex flex-col md:items-center md:justify-center md:flex-row">
      <div class="font-medium text-3xl text-surface-900 dark:text-surface-0">Powerpack Pretrips</div>
<!--      <div class="mt-4 md:mt-0">-->
<!--        <Button @click="newPretripDialogVisible = true" label="New" class="mr-2" icon="pi pi-plus" />-->
<!--      </div>-->
    </div>
  </div>
  <DataTable :value="powerPackPretrips">
    <Column field="id" header="ID"></Column>
    <Column field="time" header="Time">
      <template #body="slotProps">
        {{ new Date(slotProps.data.time).toLocaleString("en-US", {hourCycle: "h23"}) }}
      </template>
    </Column>
    <Column field="genset_number" header="Genset"></Column>
    <Column field="pretrip_pass" header="Pass/Fail">
      <template #body="slotProps">
        {{ slotProps.data.pretrip_pass ? "Pass" : "Fail" }}
      </template>
    </Column>
    <Column field="po_requested" header="PO Requested">
      <template #body="slotProps">
        {{ slotProps.data.po_requested ? "YES" : "NO" }}
      </template>
    </Column>
    <Column header="Worker">
      <template #body="slotProps">
        {{ slotProps.data.first_name + " " + slotProps.data.last_name }}
      </template>
    </Column>
    <Column field="def_liters_added" header="DEF Added"></Column>
    <Column field="oil_liters_added" header="Oil Added"></Column>
    <Column field="coolant_liters_added" header="Coolant Added"></Column>
    <Column header="Action">
      <template #body="slotProps">
        <Button text icon="pi pi-search"/>
      </template>>
    </Column>

  </DataTable>

</template>

