import {defineStore} from 'pinia';
import backendCall from "../services/AxiosService";


export const useReferenceStore = defineStore({
    id: 'referenceStore', state: () => ({
        tracks: [],
        alarms: [],
        userRoles: [],
        cnPowerPacks: [],
        clipOns: [],
        companies: [],
    }), actions: {
        async fetchReferenceData() {
            try {
                const response = await backendCall.get('reference-data');
                const referenceDataResponse = response.data.data;

                this.tracks = referenceDataResponse.tracks;
                this.alarms = referenceDataResponse.alarms;
                this.userRoles = referenceDataResponse.availableUserRoles;
                this.cnPowerPacks = referenceDataResponse.cnPowerPacks;
                this.clipOns = referenceDataResponse.clipOns;
                
                // Add companies data if available
                if (referenceDataResponse.companies) {
                    this.companies = referenceDataResponse.companies;
                }

                return response;
            } catch (error) {
                console.error('There was a problem with the axios operation:', error);
                throw error;
            }
        },
        
        async fetchCompanies() {
            // Only fetch companies if they're not already loaded
            if (this.companies.length === 0) {
                try {
                    const response = await backendCall.get('reference-data/companies');
                    if (response.data && response.data.success && response.data.data) {
                        this.companies = response.data.data;
                    }
                } catch (error) {
                    console.error('Error fetching companies:', error);
                    throw error;
                }
            }
            return this.companies;
        }
    },

});
