import {createRouter, createWebHistory} from 'vue-router'
import {useAuthStore} from "@/stores/authStore";
import Pretrips from "@/views/powerpacks/Pretrips.vue";
import GensetInventory from "@/views/yardcheck/GensetInventory.vue";
import JobDetails from "@/views/billing/JobDetails.vue";
import Builds from "@/views/powerpacks/Builds.vue";
const DeferredRequestsList = () => import("@/views/requests/DeferredRequestsList.vue");
const UnsentRequestsList = () => import("@/views/requests/UnsentRequestsList.vue");
const DomesticConsistParser = () => import("@/views/trains/DomesticConsistParser.vue");
const ConsistParser = () => import("@/views/trains/ConsistParser.vue");
const TrainLineup = () => import("../components/Trainlineup.vue");
const TrainSearchView = () => import("@/views/trains/TrainSearchView.vue");
const YardCheckUnitSearch = () => import("@/views/yardcheck/YardCheckUnitSearch.vue");
const UnattachedWaybills = () => import("@/views/waybill/UnattachedWaybills.vue");
// import NewTrain from "@/components/trains/NewTrain.vue";
// import TrainEtaUpdate from "@/components/trains/TrainEtaUpdate.vue";
// import TrainConsist from "@/components/trains/TrainConsist.vue";
// import SearchCN from "@/components/u
// nit-lookup/SearchCN.vue";
// import WaybillSearch from "@/components/waybill/WaybillSearch.vue";
// import UnitCardContainer from "@/components/UnitCardContainer.vue";
const loginPage = () => import('../views/auth/Login.vue');
const cnUnitQuery = () => import("../views/CnUnitQuery.vue");
const ConsistPage = () => import('../views/trains/ConsistPage.vue');
const MainLayout = () => import("../layout/MainLayout.vue");
const NotFoundPage = () => import('../views/errors/NotFoundPage.vue');
const RequestList = () => import('../views/requests/CurrentRequestList.vue');
const RequestDetail = () => import('../views/requests/RequestDetail.vue');
const RequestGroupList = () => import('../views/requests/RequestGroupList.vue');
const RequestGroupDetail = () => import('../views/requests/RequestGroupDetail.vue');
const RequestGroupDebug = () => import('../views/requests/RequestGroupDebug.vue');
const FuelLedger = () => import('../views/fuel/FuelLedger.vue');
const AdminPanel = () => import('../views/admin/AdminPanel.vue');
const StatisticsPage = () => import('../views/reports/StatisticsPage.vue');
const clipDashboard = () => import("../views/clipons/ClipDashboard.vue")
const Calendar = () => import('../views/Calendar.vue');
const TrainLineupDataView = () => import('../components/TrainLineupDataView.vue');
const AddBulkElectrics = () => import('../views/trains/AddBulkElectrics.vue');
const MySubscriptions = () => import('../views/user/MySubscriptions.vue');
const FieldTrainCheck = () => import('../views/trains/FieldTrainCheck.vue');
const TrainTime = () => import("../views/billing/TrainTime.vue");
const TrainHistory = () => import('../views/trains/History.vue');
const PasteableLineup = () => import('../views/trains/PasteableLineup.vue');
const YardCheckDashboard = () => import('../views/yardcheck/YardCheckDashboard.vue');
const PerformYardCheck = () => import('../views/yardcheck/PerformYardCheck.vue');
const ViewYardCheck = () => import('../views/yardcheck/ViewYardCheck.vue');
const RequestPrintConfiguration = () => import('../views/requests/RequestPrintConfiguration.vue');
const JobsView = () => import('../views/billing/JobsView.vue');
const WhosIn = () => import('../views/staffing/WhosIn.vue');
const StatusSummary = () => import('../views/fleet/StatusSummary.vue');
const MyTimes = () => import('../views/user/MyTimes.vue')
const UserSettings = () => import('../views/user/UserSettings.vue')
const Forbidden = () => import('../views/errors/Forbidden.vue');
const RequestHistoryList = () => import('../views/requests/RequestHistoryList.vue');
// Invoicing
const InvoiceList = () => import('../views/invoices/InvoiceList.vue');
const InvoiceCreate = () => import('../views/invoices/InvoiceCreate.vue');
const InvoiceDetail = () => import('../views/invoices/InvoiceDetail.vue');
// Import test harness
const FieldUnitCardTestHarness = () => import('../tests/views/TestHarness.vue');
// Import the new Bill Job View
const BillJobView = () => import('../views/billing/BillJobView.vue');
// Import the TrainSplitBillView
const TrainSplitBillView = () => import('../views/billing/TrainSplitBillView.vue');
const InvoiceReport = () => import('../views/reports/InvoiceReport.vue');


const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/',
            component: MainLayout,
            meta: {requiresAuth: true},
            children: [
                {path: '', component: TrainLineupDataView},
                {path: 'trains', name: 'TrainLineup',component: TrainLineupDataView},
                {path: 'trains/history', component: TrainHistory},
                // Disabled train search page
                // {path: 'trains/search', name: 'TrainSearch', component: TrainSearchView},
                {path: 'trains/:trainid', component: ConsistPage, props: true},
                {path: 'trains/:trainid/time', component: TrainTime, props: true},
                {path: 'trains/:trainid/consist-parser', name: 'ConsistParser', component: ConsistParser, props: true},
                {path: 'trains/:trainid/domestic-consist-parser', name: 'DomesticConsistParser', component: DomesticConsistParser, props: true},
                {path: 'trains/old', component: TrainLineup},
                {path: 'unitquery', name: 'unitQueryPage', component: cnUnitQuery},
                {path: 'requests', component: RequestList},
                {path: 'requests/:id', component: RequestDetail, props: true},
                {path: 'requests/history', component: RequestHistoryList},
                {path: 'requests/deferred-info', component: DeferredRequestsList},
                {path: 'requests/unsent', component: UnsentRequestsList},
                {path: 'requests/print-setup', name: 'requestPrintConfiguration', component: RequestPrintConfiguration},
                {path: 'fuel', name: 'FuelLedger', component: FuelLedger},
                {path: 'admin', name: 'adminPanel', component: AdminPanel, meta: { requiresAuth: true, requiredPermission: 'settings:edit' }},
                {path: '/reports/statistics', name: 'statisticsReport', component: StatisticsPage},
                {path: '/clipons', name: 'cliponDashboard', component: clipDashboard},
                {path: '/calendar', name: 'calendarComponent', component: Calendar},
                {path: 'trains/:trainid/bulkelectrics', name: 'BulkElectrics', component: AddBulkElectrics},
                // {path: 'trains/:trainid/bulkelectrics2', name: 'BulkElectrics2', component: AddBulkElectrics2},
                {path: 'user/subscriptions', name: 'UserSubscriptions', component: MySubscriptions},
                {path: 'user/times', name: 'MyTimes', component: MyTimes},
                {path: 'user/settings', name: 'UserSettings', component: UserSettings},
                {path: 'billing/jobs', name: 'JobView', component: JobsView},
                {path: 'billing/jobs/:jobId', name: 'JobDetails', component: JobDetails, props: true},
                {path: 'billing/jobs/:jobId/bill', name: 'BillJob', component: BillJobView, props: true},
                {path: 'billing/trains/:trainid/split-bill', name: 'TrainSplitBill', component: TrainSplitBillView, props: true},
                {path: 'trains/lineup/pasteable', name: 'PasteableLineup', component: PasteableLineup},
                {path: 'yard-check', name: 'YardCheckDashboard', component: YardCheckDashboard},
                // Disabled yard check unit search
                // {path: 'yard-check/search-units', name: 'YardCheckUnitSearch', component: YardCheckUnitSearch},
                {path: 'yard-check/:yardCheckId/perform', name: 'PerformYardCheck', component: PerformYardCheck, props: true},
                {path: 'yard-check/:yardCheckId', name: 'ViewYardCheck', component: ViewYardCheck, props: true},
                {path: 'staffing/whos-in', name: 'WhosIn', component: WhosIn},
                {path: 'forbidden', name: 'Forbidden', component: Forbidden},
                {path: 'fleet/summary', name: 'FleetSummary', component: StatusSummary},
                {path: 'powerpack/pretrips', name: 'PowerpackPretrips', component: Pretrips},
                {path: 'powerpack/inventory', name: 'GensetInventory', component: GensetInventory, props: true},
                {path: 'powerpack/builds', name: 'GensetBuildsList', component: Builds},
                {path: 'waybills/unattached', name: 'UnattachedWaybills', component: UnattachedWaybills},
                // {path: 'consist-parser', name: 'ConsistParser', component: ConsistParser},
                {path: 'request-groups', component: RequestGroupList},
                {path: 'request-groups/:id', component: RequestGroupDetail, props: true},
                {path: 'request-groups-debug', component: RequestGroupDebug},
                // Invoice routes
                {
                  path: 'invoices',
                  name: 'InvoiceList',
                  component: InvoiceList,
                  meta: {
                    pageTitle: 'Invoices',
                    requiresAuth: true,
                  },
                },
                {
                  path: 'invoices/create',
                  name: 'InvoiceCreate',
                  component: InvoiceCreate,
                  meta: {
                    pageTitle: 'Create New Invoice',
                    requiredPermission: 'invoices:create',
                    requiresAuth: true,
                  },
                },
                {
                  path: 'invoices/:id',
                  name: 'InvoiceDetail',
                  component: InvoiceDetail,
                  meta: {
                    pageTitle: 'Invoice Details',
                    requiresAuth: true,
                  },
                },
                {
                  path: 'invoices/:id/edit',
                  name: 'InvoiceEdit',
                  component: InvoiceCreate,
                  props: route => ({ isEditing: true, invoiceId: parseInt(route.params.id as string) }),
                  meta: {
                    pageTitle: 'Edit Invoice',
                    requiredPermission: 'invoices:edit',
                    requiresAuth: true,
                  },
                },
                {
                  path: 'invoices/report',
                  name: 'InvoiceReport',
                  component: InvoiceReport,
                  meta: {
                    pageTitle: 'Invoice Report',
                    requiresAuth: true,
                    requiredPermission: 'invoices:view'
                  },
                },
                // More authenticated routes can be nested here
            ]
        },
        {
            // The login page is outside the MainLayout
            name: 'login',
            path: '/login',
            component: loginPage,
            meta: {requiresUnauthenticated: true}
        },
        {path: '/trains/:trainid/fieldui', component: FieldTrainCheck, props: true, meta: {requiresAuth: true}},
        {path: '/tests/unit-card', component: FieldUnitCardTestHarness, name: 'FieldUnitCardTests'},
        {path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFoundPage},
    ]
})

router.beforeEach(async (to, from, next) => {
    const authStore = useAuthStore();

    // Check auth status, but with rate limiting
    // Only force a fresh check when navigating to an auth-required page from an external source
    const forceCheck = to.meta.requiresAuth && (from.name === null || from.path === '/login');
    await authStore.checkAuthStatus(forceCheck);

    if (to.meta.requiresAuth) {
        if (authStore.user) {
            // Check role-based access if specified
            if (to.meta.roles && Array.isArray(to.meta.roles)) {
                const userHasRequiredRole = to.meta.roles.some(role => 
                    authStore.user?.roles?.includes(role)
                );

                if (!userHasRequiredRole) {
                    next('/forbidden');
                    return;
                }
            }
            next();
        } else {
            next('/login');
        }
    } else if (to.meta.requiresUnauthenticated && authStore.isAuthenticated) {
        next('/');
    } else {
        next();
    }
});


export default router
