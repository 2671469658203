<script setup lang="ts">
import {computed, onMounted, ref} from "vue";
import {useAuthStore} from "../../stores/authStore";
import {useRoute} from "vue-router";
import {useToast} from "primevue/usetoast";
import {useTrainsStore} from "../../stores/trainsStore";
import {useReferenceStore} from "../../stores/referenceStore";
import backendCall from "@/services/AxiosService";



const referenceStore = useReferenceStore();
const powerpackGensets = ref([]);
const clipOns = ref([]);




const fetchPowerpackInventory = async () => {

  await backendCall.get(`${import.meta.env.VITE_API_BASE_URL}/power-pack/inventory/wit`)
      .then(response => {

        return response;
      })
      .then(data => {
        powerpackGensets.value = data?.data.data;

      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });

}

onMounted(async() => {
  await fetchPowerpackInventory();
  await referenceStore.fetchReferenceData();
   clipOns.value = referenceStore.clipOns;
});

</script>

<template>
  <div class="flex flex-col justify-between">
    <div class="bg-surface-0 dark:bg-surface-950 px-6 py-8 md:px-12 lg:px-20">
      <div class="flex flex-col md:items-center md:justify-center md:flex-row">
        <div class="font-medium text-3xl text-surface-900 dark:text-surface-0">Genset Inventory</div>
      </div>
    </div>
  </div>
  <div class="grid grid-cols-12 gap-4 mx-1">
    <div class="col-span-12 xl:col-span-6">
      <div>
        <GensetList header-title="Powerpack Gensets" genset-type="powerpack" @refresh-gensets="fetchPowerpackInventory" :genset-inventory="powerpackGensets">
        </GensetList>
      </div>
    </div>

<!--    <div class="col-span-12 xl:col-span-6">-->
<!--      <div>-->
<!--        <GensetList header-title="Clip-On Gensets" genset-type="clip" :genset-inventory="clipOns">-->
<!--        </GensetList>-->
<!--      </div>-->
<!--    </div>-->
  </div>
  <!--  Time Sidebar Button-->
<!--  <Button @click="timeTrackerSlideoverVisible = true"-->
<!--          class="!flex justify-center items-center !fixed w-[52px] h-[52px] bg-primary text-primary-content top-1/2 right-0 cursor-pointer outline-none rounded-[3px] shadow-lg"-->
<!--          style="box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);">-->
<!--    <i class="pi pi-clock leading-none"></i>-->
<!--  </Button>-->

  <!--  Time Sidebar-->
<!--  <TimeTrackerSlideover @trainCompleted="handleTrainCompleted" v-model:visible="timeTrackerSlideoverVisible"-->
<!--                        :trainId="parseInt(props.trainid)" :train-complete="trainComplete" :train-arrived="trainArrived"-->
<!--                        :user-time-record-open="userTimeRecordOpen"-->
<!--                        :user-track-protection-records="userTrackProtectionRecords" :train-active="trainActive"/>-->

</template>
