<script setup lang="ts">
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import AutoComplete from 'primevue/autocomplete';

import {PropType, ref, watch} from "vue";
import backendCall from "@/services/AxiosService";
import {useToast} from "primevue/usetoast";

const toast = useToast();

const visible = defineModel<boolean>('visible');

const props = defineProps({
  gensetsMaster: {
    type: Array as PropType<Array<{ genset_number?: string, has_def: boolean }>>,
    required: true
  },
  genset: String,
  pretripPassed: Boolean
});

const emit = defineEmits(['refreshGensets']);

const gensetSuggestions = ref<Array<{ genset_number?: string, has_def: boolean }>>([]);

const searchGensets = (event: { query: string }) => {
  const query = event.query.trim().toUpperCase();
  gensetSuggestions.value = props.gensetsMaster.filter((genset) => {
       return genset.genset_number?.includes(query)
      }
  );
};

const initializeFormData = () => {
  formData.value.genset = props.genset ? props.genset : '';
  formData.value.outType = null;
  formData.value.track = null;
  formData.value.buildStartTime = null;
  formData.value.buildCallInTime = null;
  formData.value.swappedOutGenset = null;
}

const formData = ref({
  genset: '',
  outType: null,
  track: null,
  buildStartTime: null,
  buildCallInTime: null,
  swappedOutGenset: null,
});

watch(() => props.genset, (newValue) => {
  formData.value.genset = newValue ? newValue : '';
}, {immediate: true});

const submitPowerPackDeparture = async () => {
  try {
    await backendCall.post('power-pack/inventory/wit/out', formData.value);
    emit('refreshGensets');
    visible.value = false;
    toast.add({severity: 'success', summary: 'Success', detail: 'Genset Departure Submitted', life: 5000});
  } catch (e) {
    toast.add({severity: 'error', summary: 'Error', detail: 'Error Submitting Genset.', life: 5000});
  }
}
</script>

<template>
  <Dialog
      v-model:visible="visible"
      append-to="body"
      modal
      :draggable="false"
      :resizable="false">
    <template #header>
      <div class="flex flex-col gap-2">
        <h1 class="m-0 text-surface-900 dark:text-surface-0 font-semibold text-xl leading-normal">
          Depart Genset
        </h1>
      </div>
    </template>
    <form class="flex flex-col justify-center gap-4 mt-4">
      <div>
        {{ props.genset }}
      </div>
      <div>
        <label for="out-type" class="block mb-1 text-color text-base">
          Departure Type
        </label>
        <Select id="out-type" fluid v-model="formData.outType"
                :options="props.pretripPassed ? ['build', 'deadhead', 'bo-swap', 'outgate'] : ['deadhead', 'outgate']"/>
      </div>
      <div v-if="formData.outType === 'bo-swap'">
        <div class="mt-3 w-full">
          <label for="genset-number" class="block mb-1 text-color text-base">
            Swapped Out Genset
          </label>
          <!-- @item-select="(e) => formData.swappedOutGenset = e.value.genset_number" sets formData.swappedOutGenset to the string of the genset number instead of the containing object -->
          <AutoComplete
              v-model="formData.swappedOutGenset"
              :suggestions="gensetSuggestions"
              @complete="searchGensets"
              optionLabel="genset_number"
              @item-select="(e) => formData.swappedOutGenset = e.value.genset_number"
              id="genset-number"
              name="genset-number"
              class="w-full"
          fluid />
        </div>
      </div>
      <div v-if="formData.outType === 'build' || formData.outType === 'bo-swap'">
        <div class="mt-3">
          <label for="build-track" class="block text-color text-base">
            Track
          </label>
          <Select id="build-track" fluid v-model="formData.track" option-label="trackID" option-value="id" :options="[{id: 28, trackID: 'WI01'},{id: 29, trackID: 'WI02'}]"/>
        </div>
        <div class="mt-3">
          <label for="build-call-in-time" class="block text-color text-base">
            Call In Time
          </label>
          <Calendar show-time hour-format="24" id="build-call-in-time" v-model="formData.buildCallInTime" dateFormat="mm/dd/yy"
                    class="w-full"/>
        </div>
        <div class="mt-3">
          <label for="build-start-time" class="block text-color text-base">
            Start Time
          </label>
          <Calendar show-time hour-format="24" id="build-start-time" v-model="formData.buildStartTime" dateFormat="mm/dd/yy"
                    class="w-full"/>
        </div>
      </div>
    </form>

    <template #footer>
      <div class="w-full flex gap-4 justify-end border-t border-surface pt-8">
        <Button label="Cancel" text @click="visible = false"/>
        <Button label="Submit" rounded @click="submitPowerPackDeparture"/>
      </div>
    </template>
  </Dialog>
</template>