// stores/authStore.js
import { defineStore } from 'pinia';
import backendCall from "../services/AxiosService";
import axios from "axios";
import * as Sentry from "@sentry/browser";
import router from "@/router";

interface User {
    id: number;
    username: string;
    roles: number[];
}

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;

export const useAuthStore = defineStore('auth', {
    state: () => {
        // Initialize dark mode from localStorage
        const isDarkMode = localStorage.getItem('theme') === 'dark';

        // Apply theme on initial load
        if (isDarkMode) {
            document.querySelector('html')?.classList.add('dark');
        } else {
            document.querySelector('html')?.classList.remove('dark');
        }

        return {
            isAuthenticated: false,
            user: null as User | null,
            authCheckPromise: null as Promise<void> | null,
            lastAuthCheck: 0, // Timestamp of the last auth check
            authCheckInterval: 10 * 60 * 1000, // 10 minutes in milliseconds
            darkMode: isDarkMode,
        };
    },
    getters: {
        hasPermission: (state) => (permission: string) => {
            if (!state.user?.roles) return false;

            // Basic role-based permission mapping
            const rolePermissions: Record<string, number[]> = {
                'invoices:view': [2000, 3500],
                'invoices:create': [3500],
                'invoices:edit': [3500]
            };

            // Check if the user has any of the roles required for this permission
            if (rolePermissions[permission]) {
                return rolePermissions[permission].some(role => state.user?.roles.includes(role));
            }

            // For permissions without specific mapping, default to true
            return true;
        }
    },
    actions: {
        async checkAuthStatus(force = false) {
            // If there's already a check in progress, return that promise
            if (this.authCheckPromise) {
                return this.authCheckPromise;
            }

            // Skip check if we've checked recently, unless forced
            const now = Date.now();
            if (!force && this.lastAuthCheck > 0 && (now - this.lastAuthCheck < this.authCheckInterval)) {
                return Promise.resolve();
            }

            this.authCheckPromise = backendCall.get('/auth/check')
                .then(response => {
                    this.isAuthenticated = !!response.data.user;
                    this.user = response.data.user;
                    this.lastAuthCheck = Date.now();
                })
                .catch((error) => {
                    // Only clear auth if it's actually a 401 error
                    // This prevents network errors from logging users out
                    if (error?.response?.status === 401) {
                        this.isAuthenticated = false;
                        this.user = null;
                    } else {
                        // For other errors, keep current auth state
                        console.error('Auth check failed but not clearing session:', error);
                    }
                })
                .finally(() => {
                    this.authCheckPromise = null;
                });

            return this.authCheckPromise;
        },

        async login(credentials: { username: string, password: string }) {
            try {
                const response = await backendCall.post('/auth/login', credentials);
                this.isAuthenticated = true;
                this.user = response.data.user;
                this.lastAuthCheck = Date.now(); // Update the last check time after successful login
                Sentry.setUser({
                    id: response.data.user.id,
                    username: response.data.user.username,
                    roles: response.data.user.roles,
                });
                return response;
            } catch (error) {
                // Re-throw the error so it can be handled by the component
                throw error;
            }
        },

        async logout() {
            try {
                await axios.get(`${API_BASE_URL}auth/logout`, {withCredentials: true});
            } catch (error) {
                console.error('Logout error:', error);
                // Continue with local logout even if server logout fails
            }

            this.isAuthenticated = false;
            this.user = null;
            this.lastAuthCheck = 0;
            Sentry.setUser(null);

            await router.push('/login');
        },

        toggleDarkMode() {
            this.darkMode = !this.darkMode;
            const element = document.querySelector('html');
            if (this.darkMode) {
                element.classList.add('dark');
            } else {
                element.classList.remove('dark');
            }
            localStorage.setItem('theme', this.darkMode ? 'dark' : 'light');
        }
    }
});
