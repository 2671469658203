<script setup lang="ts">
import {PropType, ref} from "vue";
import ConfirmPopup from "primevue/confirmpopup";
import NewInventoryClip from "@/components/yard-check/NewInventoryClip.vue";
import {useReferenceStore} from "@/stores/referenceStore";
import DepartPowerPack from "@/components/yard-check/DepartPowerPack.vue";

const props = defineProps({
  headerTitle: String,
  units: Object,
  detailLevel: String,
  gensetType: String,
  gensetInventory: {
    type: Array as PropType<Array<{ genset_number?: string, clip_number?: string, has_def: boolean }>>,
    required: true
  }
})

const emit = defineEmits(['refreshGensets']);


const referenceStore = useReferenceStore();

const newPretripDialogVisible = ref(false);
const newPretripSelectedGenset = ref();

const openNewPretripDialog = (gensetNumber: string) => {
  newPretripSelectedGenset.value = gensetNumber;
  newPretripDialogVisible.value = true;
}

const departPowerPackDialogVisible = ref(false);
const departPowerPackSelectedGenset = ref('');
const departPowerPackPretripPassed = ref(false);

const openDepartPowerPackDialog = (gensetNumber: string, pretripPassed: boolean) => {
  departPowerPackSelectedGenset.value = gensetNumber;
  departPowerPackDialogVisible.value = true;
  departPowerPackPretripPassed.value = pretripPassed;
}

const newInventoryGensetDialogVisible = ref(false);

const unitWaybillAndLocationDialogVisible = ref(false);
const selectedUnitInfo = ref();


</script>

<template>
  <NewInventoryPowerPack v-if="gensetType === 'powerpack' && newInventoryGensetDialogVisible"
                         v-model="newInventoryGensetDialogVisible" @refresh-gensets="emit('refreshGensets')"
                         :gensets-master="referenceStore.cnPowerPacks"/>
  <!--  <NewInventoryClip :gensets-master="gensetsMaster" v-if="gensetType === 'clip'" v-model="newInventoryGensetDialogVisible"/>-->
  <Card>
    <template #header>
      <h3 class="text-center">{{ headerTitle }}</h3>
      <div class="flex justify-between">
        <div>
        </div>
        <div class="flex justify-end">
          <Button @click="newInventoryGensetDialogVisible = true" class="mr-6" icon="pi pi-plus" severity="success"
                  aria-label="New" outlined label="New"/>
        </div>
      </div>
    </template>
    <template #content>
      <DataTable :value="gensetInventory" sort-field="in_time" showGridlines stripedRows size="small">
        <Column field="genset_number" header="Genset" :sortable="true" style="width: 3rem;"
                class="text-center text-sm"></Column>
        <Column header="In Time" field="in_time" :sortable="true" style="width: 10rem;">
          <template #body="slotProps">
            {{
              slotProps.data.in_time ? new Date(slotProps.data.in_time).toLocaleString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hourCycle: 'h23'
              }) : ''
            }}
          </template>
        </Column>
        <Column field="in_type" header="Type" :sortable="true" style="width: 3rem;"
                class="text-right text-sm"></Column>
        <Column header="entered_by" class="text-sm">
          <template #body="slotProps">
            {{ slotProps.data.recorded_by_first_name + ' ' + slotProps.data.recorded_by_last_name }}
          </template>
        </Column>
        <!--        <Column field="railcar" header="Car" :sortable="true" style="width: 5rem" class="text-center text-sm"></Column>-->
        <Column header="Pretrip" style="width: 2rem;">
          <template #body="slotProps">
            <div class="flex justify-center">
              {{ slotProps.data.latest_pretrip_passed ? "PASS" : "" }}
              <Button v-if="slotProps.data.latest_pretrip_passed == null"
                      @click="openNewPretripDialog(slotProps.data.genset_number)" outlined size="small"
                      label="Pretrip"/>
              <Button v-if="slotProps.data.latest_pretrip_passed === false"
                      @click="openNewPretripDialog(slotProps.data.genset_number)" severity="danger" outlined size="small"
                      label="Failed"/>

              <!--              <Button @click="confirm1($event)" size="small" class="p-0 my-0" severity="danger" text rounded aria-label="Cancel">-->
              <!--              <i class="pi pi-times" aria-label="Delete"/>-->
              <!--              </Button>-->
            </div>
          </template>
        </Column>
        <Column style="width: 2rem;">
          <template #body="slotProps">
            <div class="flex justify-between">
              <Button @click="openDepartPowerPackDialog(slotProps.data.genset_number, slotProps.data.latest_pretrip_passed)" severity="warn" outlined
                      size="small" label="Out"/>

              <!--                            <Button @click="confirm1($event)" size="small" class="p-0 my-0" severity="danger" text rounded aria-label="Cancel">-->
              <!--                            <i class="pi pi-times" aria-label="Delete"/>-->
              <!--                            </Button>-->
            </div>
          </template>
        </Column>
      </DataTable>
    </template>
  </Card>
  <NewPretripDialog v-model:visible="newPretripDialogVisible" v-if="newPretripDialogVisible"
                    :genset-number="newPretripSelectedGenset" @refresh-pretrips="emit('refreshGensets')"/>
  <DepartPowerPack class="w-96" v-model:visible="departPowerPackDialogVisible" v-if="departPowerPackDialogVisible"
                   @refresh-gensets="emit('refreshGensets')" :genset="departPowerPackSelectedGenset" :pretrip-passed="departPowerPackPretripPassed"
                   :gensets-master="referenceStore.cnPowerPacks"/>
</template>

