<script setup lang="ts">
import {computed} from 'vue';
import Button from 'primevue/button';

const baseUrl = import.meta.env.VITE_BACKEND_BASE_URL;

const props = defineProps({
  trainId: String,
  jobId: String,
  title: String,
  startTime: String,
  totalElectrics: {
    type: Number,
    default: 0
  },
  totalGensets: {
    type: Number,
    default: 0
  },
  totalDomestics: {
    type: Number,
    default: 0
  },
  totalRequests: {
    type: Number,
    default: 0
  },
  totalBillingTime: {
    type: String,
    default: '00:00:00'
  },
  totalWaitTime: {
    type: String,
    default: '00:00:00'
  },
  totalTrackProtectionTime: {
    type: String,
    default: '00:00:00'
  },
  workersOnTrain: {
    type: Array,
    default: () => []
  },
  workersOnTrainCount: {
    type: Number,
    default: 0
  },
  trainArrivalTime: String
})

// const dashedTrainId = computed(() => {
//   if (props?.trainId) {
//     return toDashedTrainId(props.trainId);
//   }
// });

</script>


<template>
  <div class="bg-surface-0 dark:bg-surface-950 px-2 py-4 md:px-2 lg:px-2 flex flex-wrap justify-center">
    <div class="flex justify-between items-center w-full">
      <div></div>
      <div class="flex text-surface-900 dark:text-surface-0 text-3xl font-medium mb-4 cursor-pointer">
        {{ title || 'Job Statistics' }}
      </div>
      <div v-if="trainId">
        <a :href="`${baseUrl}trains/${trainId}/time-report-pdf`">
          <Button label="PDF" class="p-button-outlined mr-2" icon="pi pi-print"></Button>
        </a>
      </div>
    </div>
    
    <div class="flex flex-col xl:flex-row w-full items-center justify-between">
      <div class="w-full flex flex-col">
        <div class="flex items-center justify-evenly text-surface-600 dark:text-surface-200">
          <span v-if="totalGensets > 0" class="mr-4"><i class="pi pi-bolt mr-2"/>{{ totalGensets }} Gensets</span>
          <span v-if="totalElectrics > 0" class="mx-4"><i class="pi pi-bolt mr-2"/>{{ totalElectrics }} Electrics</span>
          <span v-if="totalRequests > 0" class="mx-4"><i class="pi pi-exclamation-circle mr-2"/>{{ totalRequests }} Requests</span>
          <span v-if="totalDomestics > 0" class="mx-4"><i class="fa-solid fa-gas-pump mr-2"></i>{{ totalDomestics }} Domestics</span>
        </div>
      </div>
      
      <div class="w-full md:w-full flex flex-col sm:flex-row gap-2 justify-evenly xl:justify-end mt-2">
        <div v-if="workersOnTrainCount > 0" class="flex items-center mr-0 md:mr-8">
          <span class="rounded-full flex items-center justify-center bg-cyan-300 text-cyan-800 mr-4 w-14 h-14">
            <i class="pi pi-users !text-3xl"/>
          </span>
          <div>
            <span class="font-medium text-surface-600 dark:text-surface-200">Workers</span>
            <p class="mt-1 mb-0 text-surface-900 dark:text-surface-0 text-2xl font-medium">
              {{ workersOnTrainCount }}
            </p>
          </div>
        </div>
        
        <div v-if="trainArrivalTime" class="flex items-center mt-4 md:mt-0">
          <span class="rounded-full flex items-center justify-center bg-yellow-300 text-yellow-800 mr-4 w-14 h-14">
            <i class="pi pi-clock !text-3xl"/>
          </span>
          <div>
            <span class="font-medium text-surface-600 dark:text-surface-200">Start Time</span>
            <p class="mt-1 mb-0 text-surface-900 dark:text-surface-0 text-2xl font-medium whitespace-nowrap">
              {{ trainArrivalTime ? new Date(trainArrivalTime).toLocaleString('en-US', {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: '2-digit',
                hourCycle: 'h23'
              }) : '- - - -' }}
            </p>
          </div>
        </div>
      </div>
    </div>
    
    <div class="bg-surface-50 w-full dark:bg-surface-950 mt-4">
      <div class="bg-surface-50 dark:bg-surface-950 px-2 py-1 md:px-2 lg:px-2">
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-12 md:col-span-6 lg:col-span-4 p-2">
            <div class="p-4 text-center bg-blue-500 rounded-xl">
              <span class="inline-flex justify-center items-center bg-blue-600 rounded-full mb-4 w-12 h-12 py-2">
                <i class="pi pi-clock !text-xl leading-none text-white"/>
              </span>
              <div class="text-2xl font-medium text-white mb-2">{{ totalBillingTime || '00:00:00' }}</div>
              <span class="text-blue-100 font-medium">Total Billing Time</span>
            </div>
          </div>
          
          <div class="col-span-12 md:col-span-6 lg:col-span-4 p-2">
            <div class="p-4 text-center bg-purple-500 rounded-xl">
              <span class="inline-flex justify-center items-center bg-purple-600 rounded-full mb-4 w-12 h-12 py-2">
                <i class="pi pi-clock !text-xl leading-none text-white"/>
              </span>
              <div class="text-2xl font-medium text-white mb-2">{{ totalWaitTime || '00:00:00' }}</div>
              <span class="text-purple-100 font-medium">Total Wait Time</span>
            </div>
          </div>
          
          <div class="col-span-12 md:col-span-6 lg:col-span-4 p-2">
            <div class="p-4 text-center bg-indigo-500 rounded-xl">
              <span class="inline-flex justify-center items-center bg-indigo-600 rounded-full mb-2 w-12 h-12 py-2">
                <i class="pi pi-clock !text-xl leading-none text-white"/>
              </span>
              <div class="text-2xl font-medium text-white mb-2">{{ totalTrackProtectionTime || '00:00:00' }}</div>
              <span class="text-indigo-100 font-medium">Total Track Protection Time</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* You can add custom styles here if needed */
</style>
