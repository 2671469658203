<script setup lang="ts">
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import { ref, watch } from 'vue';
import backendCall from "../../services/AxiosService";
import {useToast} from "primevue/usetoast";

const props = defineProps({
timeRecord: Object,
})

const emit = defineEmits(['refreshTimes']);


const toast = useToast();

const visible = defineModel('visible');

const workerOptions = ref([

]);

const timeRecord = ref({
user: null,
startTime: null,
endTime: null,
});

watch(() => props.timeRecord,(newValue) => {
timeRecord.value.first_name = newValue.first_name;
timeRecord.value.last_name = newValue.last_name;
timeRecord.value.startTime = newValue.start_time ? new Date(newValue.start_time) : null;
timeRecord.value.endTime = newValue.end_time ? new Date(newValue.end_time) : null;
});


const submitTimeRecord = async (data) => {
  try {
    await backendCall.patch(`time/${props.timeRecord.time_record_id}`, timeRecord.value);
    timeRecord.value.startTime = null;
    timeRecord.value.endTime = null;
    visible.value = false;
    emit("refreshTimes");
  } catch (e) {
    toast.add({severity: 'error', summary: 'Error', detail: 'Error Updating Time Record.', life: 5000});

  }
}



</script>

<template>
  <Dialog v-model:visible="visible" append-to="body" modal :breakpoints="{ '960px': '75vw', '640px': '96vw' }" :style="{ width: '52vw' }" :draggable="false" :resizable="false">
      <template #header>
        <div class="flex flex-col gap-2">
          <h1 class="m-0 text-surface-900 dark:text-surface-0 font-semibold text-xl leading-normal">Edit Time Record</h1>
<!--          <span class="text-surface-600 dark:text-surface-200 text-base">Quis enim lobortis scelerisque fermentum dui faucibus in ornare quam.</span>-->
        </div>
      </template>
      <form class="flex flex-col gap-4 mt-4">
        <div>
          <label for="worker" class="block mb-1 text-color text-base">Worker</label>
          <div class="font-bold">
          {{timeRecord.first_name}} {{timeRecord.last_name}}
          </div>
<!--          <InputGroup>-->
<!--            <InputGroupAddon>-->
<!--              <i class="pi pi-user"></i>-->
<!--            </InputGroupAddon>-->
<!--            <Select id="worker" name="worker" dropdown-icon="pi pi-user" v-model="timeRecord.user" option-value="id" :option-label="(data) => { return data.first_name + ' ' + data.last_name}" :options="workerOptions" class="w-full">-->
<!--              <template #option="option">-->
<!--                {{option.option.first_name + ' ' + option.option.last_name}}-->
<!--              </template>-->
<!--            </Select>-->
<!--          </InputGroup>-->
        </div>

        <div class="flex gap-4">
          <div class="w-full">
            <label for="start-time" class="block mb-1 text-color text-base">Start Time</label>
            <InputGroup>
              <input-group-addon>
              <i class="pi pi-clock" />
              </input-group-addon>
              <DatePicker id="start-time" v-model="timeRecord.startTime" showTime hourFormat="24" fluid />
            </InputGroup>
          </div>
          <div class="w-full">
            <label for="end-time" class="block mb-1 text-color text-base">End Time</label>
            <InputGroup>
              <input-group-addon>
                <i class="pi pi-clock" />
              </input-group-addon>
              <DatePicker id="end-time" v-model="timeRecord.endTime" showTime hourFormat="24" fluid />
            </InputGroup>
          </div>
        </div>
      </form>
      <template #footer>
        <div class="w-full flex gap-4 justify-end border-t border-surface pt-8">
          <Button label="Cancel" text @click="visible = false" />
          <Button label="Submit" rounded @click="submitTimeRecord" />
        </div>
      </template>
    </Dialog>
</template>

